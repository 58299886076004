<template>
  <div>
    <el-drawer :visible.sync="staffDrawer" :with-header="true" @close="close" :show-close="false" :size="size"
      @open="openStaff()">
      <div slot="title" class="title_stafDrawer">
        <div class="stafDrawer_top">
          <span>员工数据统计</span><span>( 员工数据以已注册的为基础进行统计，游客不算在内 )</span>
        </div>
        <span @click="close" class="back">返回</span>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据总量：</span>
        </div>
        <div class="demo_isone">
          <div class="isone_div"><span style="font-weight:700;font-size:14px">{{ stafDrawerList.reg_user_num }}</span>
            <br />
            <span style="font-size:14px">注册员工人数</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ stafDrawerList.authed_num }}</span>
            <br />
            <span style="font-size:14px">已实名认证人数</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ stafDrawerList.erlihu_num }}</span>
            <br />
            <span style="font-size:14px">已开二类户人数</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ stafDrawerList.upNum }}</span>
            <br />
            <span style="font-size:14px">二升一申请人数</span>
          </div>
          <div class="isone_div"> <span style="font-weight:700;font-size:14px">{{ stafDrawerList.active }}</span>
            <br />
            <span style="font-size:14px">已激活卡数</span>
          </div>

        </div>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>今日新增：</span>
        </div>
        <div class="demo_isone">
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_reg_user_num
          }}</span> <br />
            <span style="font-size:14px">注册员工人数</span>
          </div>
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_authed_num
          }}</span> <br />
            <span style="font-size:14px">已实名认证人数</span>
          </div>
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_erlihu_num
          }}</span> <br />
            <span style="font-size:14px">已开二类户人数</span>
          </div>
          <div class="isone_div"><span style="color:red">+</span> <span style="font-size:14px">{{
              stafDrawerList.today_upNum
          }}</span> <br />
            <span style="font-size:14px">二升一申请人数</span>
          </div>
        </div>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据走势：</span>
        </div>
        <div
          style="text-align:left;font-size:14px;display:flex;align-items:center;margin-top:10px;justify-content:space-between">
          <div>
            <span>数据时间</span>
            <el-radio-group v-model="timeType" style="margin-left:10px" @change="typeChange">
              <el-radio-button label="本年"></el-radio-button>
              <el-radio-button label="月份"></el-radio-button>
              <el-radio-button label="近7日"></el-radio-button>
            </el-radio-group>
          </div>

          <el-input type="text" v-if="timeType === '近7日'" placeholder="近7日" disabled size="mini" style="width:100px" />
          <el-select v-if="timeType === '月份'" v-model="date_new" placeholder="请选择" size="mini" @change="changDate">
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input type="text" v-if="timeType === '本年'" placeholder="本年" size="mini" disabled style="width:100px" />
        </div>
      </div>
      <div ref="echartBox1" style="height: 500px"></div>
      <div style="text-align:left;color:#aaaaaa;padding: 0 20px;font-size: 16px">
        <span v-if="timeType === '本年'">{{ date_year }}</span>
        <span v-if="timeType === '月份'">{{ date_month }}</span>
        <span v-if="timeType === '近7日'">{{ timeType }}</span>累计：注册 {{ regTotal }} 人，认证 {{ authedTotal }} 人，开户 {{
            erlihuTotal
        }}
        人，二升一申请 {{ upTotal }} 人
      </div>
    </el-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import * as _ from 'lodash';
import { api } from '/src/api/base';
export const dashboardEnterpriseIndexUser = api()('/dashboard.enterprise.index.user.json');
import * as echarts from 'echarts';
export const dashboardEnterpriseIndexUserDatas = api()('/dashboard.enterprise.index.user.datas.json')
export const dashboardEnterpriseInfoTwo = api()('dashboard.enterprise.index2.json');
export default {
  data () {
    return {
      drawer: this.staffDrawer,
      size: '50%',
      timeType: '近7日',
      tempData: null,
      stafDrawerList: {},
      dateList: [
        {
          value: '01',
          label: '1月'
        },
        {
          value: '02',
          label: '2月'
        },
        {
          value: '03',
          label: '3月'
        },
        {
          value: '04',
          label: '4月'
        },
        {
          value: '05',
          label: '5月'
        },
        {
          value: '06',
          label: '6月'
        },
        {
          value: '07',
          label: '7月'
        },
        {
          value: '08',
          label: '8月'
        },
        {
          value: '09',
          label: '9月'
        },
        {
          value: '10',
          label: '10月'
        },
        {
          value: '11',
          label: '11月'
        },
        {
          value: '12',
          label: '12月'
        }
      ],
      date_new: '',
      regTotal: '',
      upTotal: '',
      erlihuTotal: '',
      authedTotal: '',
      date_year: '',
      date_month: ''

    }
  },
  components: {

  },
  props: {
    staffDrawer: Boolean
  },

  methods: {
    async openStaff () {
      this.loadData()
      this.date_new = moment().format('MM')
      this.date_month = moment().format('YYYY年MMM')
      this.date_year = moment().format('YYYY年')
      await this.getData();

      setTimeout(() => {
        this.setOption();
        console.log('====222===', this.$refs);
      }, 1000);
    },
    async loadData () {
      const data = await dashboardEnterpriseIndexUser({

      })
      console.log(data);
      this.stafDrawerList = data
    },
    close () {
      this.timeType = '近7日'
      this.drawer = false;
      this.$emit('close', this.drawer)
    },
    async changDate (e) {
      console.log(this.date_month.slice(0, 5), e.slice(1, 2));
      this.date_month = this.date_month.slice(0, 5) + e.slice(1, 2) + '月'
      this.date_new = e
      await this.getData()

    },
    async getData () {
      let params = {}
      if (this.timeType === '月份') {
        params = {
          type: this.timeType === '近7日' ? 'week' : this.timeType === '月份' ? 'month' : 'year',
          month: moment().format('YYYY') + this.date_new
        }
      }
      else {
        params = {
          type: this.timeType === '近7日' ? 'week' : this.timeType === '月份' ? 'month' : 'year',

        }
      }
      const data = await dashboardEnterpriseIndexUserDatas(params);
      this.tempData = data;
      this.regTotal = _.sumBy(data.reg, it => Number(it.num))
      console.log(this.regTotal);
      this.upTotal = _.sumBy(data.up, it => Number(it.num))
      this.erlihuTotal = _.sumBy(data.erlihu, it => Number(it.num))
      this.authedTotal = _.sumBy(data.authed, it => Number(it.num))
      this.setOption()
    },
    setOption () {
      const list = this.tempData;
      var myChart = echarts.init(this.$refs.echartBox1);
       window.onresize = function() {
    myChart.resize();
  };
      var option = {
        grid: {
          top: '18%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
          },
          formatter: (params) => {
            const dict = { '认证': '实名认证', '开户': '开二类户', '注册': '注册员工', '二升一': '二升一申请' }
            return `<div style="text-align:left;padding:5px">${this.timeType === '本年' ? moment(params[0].name).format('YYYY-MM') : moment(params[0].name).format('YYYY-MM-DD')}<br/>${params.map(n => `${dict[n.seriesName]}:${n.value}个`).join('<br/>')}</div>`;
          },
        },
        legend: {
          orient: 'horizontal',
          right: '10%',
          top: '10%',
          data: ['二升一', '开户', '认证', '注册'],
          textStyle: {
            'fontSize': 14,
          },
          icon: 'rect',
        },
        xAxis: {
          name: '日期',
          nameTextStyle: {
            color: '#595959',
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: list.authed.map(it => it.date),
        },

        yAxis: {
          name: '人数',
          nameTextStyle: {
            align: 'center',
            color: '#595959',
          },
        },
        series: [
          {
            name: '认证',
            type: 'line',
            itemStyle: {
              color: '#8400ff',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.authed.map(it => it.num),
          },

          {
            name: '开户',
            type: 'line',
            itemStyle: {
              color: '#02a7f0',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.erlihu.map(it => it.num),
          },
          {
            name: '注册',
            type: 'line',
            itemStyle: {
              color: '#6db103',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.reg.map((it) => it.num),
          },
          {
            name: '二升一',
            type: 'line',
            itemStyle: {
              color: '#f59a23',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.up.map((it) => it.num),
          },
        ],
      };
      myChart.setOption(option, true);

    },
    async typeChange () {
      await this.getData();
      this.setOption();
    },
  },

}
</script>
<style >
.el-drawer__header {
  text-align: left;
}

.el-radio-button__inner {
  padding: 5px 10px;
  border-radius: 0;
}
</style>
<style lang="scss" scoped>
.title_stafDrawer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .stafDrawer_top {
    span:nth-child(1) {
      font-size: 20px;
      color: #333333;
    }

    span:nth-child(2) {
      color: #b2aabd;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .back {
    color: #1989fa;
    font-size: 14px;
  }
}

.stafDrawer_demo {
  padding: 0 20px;
}

.demo_isone {
  padding: 20px;
  display: flex;
  align-items: center;

  .isone_div {
    padding: 20px 30px;
  }
}
</style>